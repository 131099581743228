@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
	font-family: "Montserrat", Helvetica, sans-serif;
	background-color: #ff89c7;
	// background-color: #002a2a;
	transition: background-color .2s, color .2s;
	padding: 0;
	margin: 0;
	cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' ...") 16 16, pointer;
}

.content-wrapper {
	display: flex;
	position: relative;
	height: 100vh;
	overflow: hidden;
	overflow-y: auto;
	justify-content: space-between;
	scroll-behavior: smooth;
	flex-flow: row wrap;

	// width: calc(100vw - 20rem);
	// padding: 0 20rem 0 0;

	main {
		padding-top: 2rem;
	}

	.logo {
		display: block;
    padding: 0 2rem;

		img,
		svg {
			max-width: 10rem;
		}

		svg .rotate {
			animation: logo-rotate 5s infinite linear;
			transform-origin: 50% 50%;
			animation-play-state: paused;
		}

		&:hover {
			svg .rotate {
				animation-play-state: running;
			}
		}
	}

	nav {
		padding: 2rem;
		color: #ff242f;
		// mix-blend-mode: difference;
		// animation: color-rotate 30s infinite;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			font-size: 12vmin;
			font-weight: 700;
			line-height: 1;
			padding-bottom: 5rem;
		}

		li {
			a {
				position: relative;

				&[href='#'] {
					// text-decoration: line-through;
					text-decoration-color: #F0EB4A;
				}

				&::after {
					content: "";
					position: absolute;
					width: 4rem;
					height: 4rem;
					top: -2rem;
					right: -2rem;
					background-repeat: no-repeat;
					background-position: 50% 50%;
					pointer-events: none;
				}
			}

			&:nth-child(2) {
				a {
					&::after {
						background-image: url('/assets/ikoner-01.svg');
					}

					&:hover {
						&::after {
							background-image: url('/assets/ikoner-02.svg');
						}
					}
				}
			}

			&:nth-child(5) {
				a {
					&::after {
						background-image: url('/assets/ikoner-03.svg');
					}

					&:hover {
						&::after {
							background-image: url('/assets/ikoner-04.svg');
						}
					}
				}
			}

			&:nth-child(6) {
				a {
					&::after {
						background-image: url('/assets/ikoner-09.svg');
						right: 5rem;
						transform: translateY(1rem);
						opacity: 0;
						transition: transform 0.5s, opacity 0.5s;
					}

					&:hover::after {
						transform: translateY(0);
						opacity: 1;
					}
				}
			}

			&:nth-child(7) {
				a {
					&::after {
						background-image: url('/assets/ikoner-05.svg');
					}

					&:hover {
						&::after {
							background-image: url('/assets/ikoner-06.svg');
						}
					}
				}
			}
		}

		a {
			text-decoration: none;
			color: inherit;
			transition: color 0.2s;

			&:hover {
				color: #000;
			}
		}
	}

	aside.sidebar {
		position: sticky;
		top: 0;
		right: 0;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		flex: 0 0 20rem;
		max-width: 20rem;
		width: 100%;
		padding: 2rem;

		a {
			color: inherit;
		}
	}

	.contact {
		text-align: right;
		display: none;
	}

	.info {
		width: 100%;
	}
}

.button {
	display: inline-block;
	border: 2px solid currentColor;
	color: inherit;
	padding: 0.75rem 1.5rem;
	text-decoration: none;
	border-radius: 2em;
	font-weight: 600;
	font-size: 1.5rem;
}

@media (min-width: 30rem) {
	.content-wrapper {
		flex-flow: row nowrap;

		.logo {
			img,
			svg {
				max-width: 16rem;
			}
		}

		nav {
			padding: 5rem 2rem;

			li {
				a {
					&::after {
						width: 10rem;
						height: 10rem;
						top: -3rem;
						right: -8rem;
					}
				}
			}
		}

		main {
			padding-bottom: 5rem;
		}
	}

	aside.sidebar {
		.contact {
			display: block;
		}
	}
}



@keyframes color-rotate {
	0%,
	100% {
		color: #ff89c7;
		// background-color: #002a2a;
	}

	33% {
		color: #ff242f;
		// background-color: #ff89c7;
	}

	66% {
		color: #002a2a;
		// background-color: #ff242f;
	}
}


@keyframes logo-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// Booking Popup

html.etpPopup {
	min-height: 100%;
	position: relative;
	scroll-behavior: smooth
}

html.etpPopup body {
	height: 100%
}

#BookingBackground {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: scroll;
	z-index: 100000;
	text-align: center;
	background-color: rgba(0,0,0,.8)
}

html.etpPopup #BookingBackground {
	display: block
}

#BookingBackground #EtbFrame {
	width: 95%;
	max-width: 700px;
	min-width: 330px;
	height: 670px;
	max-height: 670px;
	background-color: #fff;
	margin-top: 15px;
	margin-bottom: 10px;
	box-shadow: 0 0 8px #000;
	-webkit-transition: max-height .2s;
	-moz-transition: max-height .2s;
	-ms-transition: max-height .2s;
	-o-transition: max-height .2s;
	transition: max-height .2s;
	overflow: hidden
}
